import { TAP_API_BASE_URL } from 'constants/ENV';
import { BOOKINGS_ENABLED, DELAY } from 'constants/MOCK_CONFIG';
import { HttpResponse, delay, http } from 'msw';

const handlers = [];

if (BOOKINGS_ENABLED) {
  const url = `${TAP_API_BASE_URL}/*/bookings`;

  handlers.push(
    http.get(url, async () => {
      const json = await import('mocks/data/bookings/bookings.json');
      await delay(DELAY);
      return HttpResponse.json(json);
    })
  );
}

export default handlers;
