import { OFFICE_BY_COUNTRY, OFFICES } from 'constants/COUNTRIES';

const getOneTrust = async () => {
  return new Promise((resolve) => {
    const MAX_TRIES = 20;
    const INTERVAL_MILLISECONDS = 200;
    let oneTrust = window?.OneTrust;
    if (oneTrust === undefined) {
      let count = 0;
      const otCancelToken = setInterval(() => {
        oneTrust = window?.OneTrust;
        count += 1;
        if (oneTrust || MAX_TRIES === count) {
          clearInterval(otCancelToken);
          resolve(oneTrust);
        }
      }, INTERVAL_MILLISECONDS);
    } else {
      resolve(oneTrust);
    }
  });
};

const getOneTrustOffice = async () => {
  const oneTrust = await getOneTrust();
  const geoLocationData = oneTrust?.getGeolocationData();
  return OFFICE_BY_COUNTRY?.[geoLocationData?.country?.toUpperCase()] || OFFICES.US;
};

export { getOneTrust, getOneTrustOffice };
