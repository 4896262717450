import LoadingPage from '@viking-eng/loading-page';
import './PageLoading.scss';

const PageLoading = () => (
  <div className="page-loading text-center align-content-center">
    <div>
      <LoadingPage variant="blue" />
    </div>
  </div>
);

PageLoading.propTypes = {};

export default PageLoading;
