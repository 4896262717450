import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
  filterSelections: { isSoldOutVisible: true },
  selectedItinerary: null,
};

const itinerarySlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setFilterSelections: (state, action) => {
      return {
        ...state,
        filterSelections: action.payload,
      };
    },
    setSelectedItinerary: (state, action) => {
      return {
        ...state,
        selectedItinerary: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFilterSelections, setSelectedItinerary } = itinerarySlice.actions;

export default itinerarySlice;
