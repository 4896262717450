import { API_PATHS } from 'constants/API';
import tapApi from './api';

const metaApi = tapApi.injectEndpoints({
  endpoints: (build) => ({
    getMeta: build.query({
      queryFn: async (_args, _api, _extraOptions, baseQuery) => {
        const response = await baseQuery(API_PATHS.meta());
        return response;
      },
    }),
  }),
});

const { useGetMetaQuery } = metaApi;

export { useGetMetaQuery };
