import { useGetCmsQuery } from 'app/services/tap/cms';
import PageLoading from 'common/pageLoading/PageLoading';
import { CMS_PATHS } from 'constants/API';
import { OFFICES } from 'constants/COUNTRIES';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const CommonWrapper = ({ children }) => {
  const { office } = useSelector((state) => state?.auth?.agency || { country: OFFICES.US, office: OFFICES.US });

  const { isError, isFetching } = useGetCmsQuery({
    cmsPath: CMS_PATHS.pages.common,
    office,
  });

  return !isError && !isFetching ? children : <PageLoading />;
};

CommonWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default CommonWrapper;
