const CMS_OFFICE_CODE_BY_COUNTRY = Object.freeze({
  AU: 'AU',
  CA: 'CA',
  GB: 'UK',
  NZ: 'AU',
  UK: 'UK',
  US: 'US',
});

const COUNTRY = Object.freeze({
  AU: 'AU',
  CA: 'CA',
  GB: 'UK',
  NZ: 'NZ',
  UK: 'UK',
  US: 'US',
});

const COUNTRY_BY_CURRENCY = Object.freeze({
  AUD: COUNTRY.AU,
  CAD: COUNTRY.CA,
  GBP: COUNTRY.GB,
  USD: COUNTRY.US,
});

const CURRENCY_SYMBOLS = Object.freeze({
  AUD: 'AU$',
  CAD: '$',
  GBP: '£',
  USD: '$',
});

const CURRENCY_BY_COUNTRY = Object.freeze({
  AU: { code: 'AUD', symbol: CURRENCY_SYMBOLS.AUD },
  CA: { code: 'CAD', symbol: CURRENCY_SYMBOLS.CAD },
  UK: { code: 'GBP', symbol: CURRENCY_SYMBOLS.GBP },
  US: { code: 'USD', symbol: CURRENCY_SYMBOLS.USD },
});

const DATE_BY_COUNTRY = Object.freeze({
  AU: 'dd MMM yyyy',
  CA: 'MMM dd, yyyy',
  GB: 'dd MMM yyyy',
  NZ: 'dd MMM yyyy',
  UK: 'dd MMM yyyy',
  US: 'MMM dd, yyyy',
});

const ISO_CODES_BY_COUNTRY = Object.freeze({
  AU: 'en-AU',
  CA: 'en-CA',
  GB: 'en-GB',
  UK: 'en-GB',
  US: 'en-US',
});

const SUPPORT_BY_COUNTRY = Object.freeze({
  AU: {
    code: 'AU',
    defaultSortPosition: 2,
    email: 'agents.anz@viking.com',
    hasSupport: true,
    name: 'Australia',
    phone: '138 747',
    shortName: 'AU',
  },
  CA: {
    code: 'CA',
    defaultSortPosition: 1,
    email: 'salessupport@viking.com',
    hasSupport: true,
    name: 'Canada',
    phone: '1-855-929-6215',
    shortName: 'Canada',
  },
  NZ: {
    code: 'NZ',
    defaultSortPosition: 3,
    email: 'agents.anz@viking.com',
    hasSupport: true,
    name: 'New Zealand',
    phone: '0800 447 913',
    shortName: 'NZ',
  },
  UK: {
    code: 'UK',
    defaultSortPosition: 4,
    email: 'salesuk@viking.com',
    hasSupport: false,
    name: 'United Kingdom',
    phone: '0800 008 6832',
    shortName: 'UK',
  },
  US: {
    code: 'US',
    defaultSortPosition: 0,
    email: 'salessupport@viking.com',
    hasSupport: true,
    name: 'United States',
    phone: '1-855-706-2326',
    shortName: 'US',
  },
});

const OFFICES = Object.freeze({
  AU: 'AU',
  CA: 'CA', // Technically this isn't an office, CA is part of US office
  UK: 'UK',
  US: 'US',
});

const OFFICES_ALL = Object.values(OFFICES);

const OFFICE_BY_COUNTRY = Object.freeze({
  AU: OFFICES.AU,
  CA: OFFICES.US,
  GB: OFFICES.UK,
  NZ: OFFICES.AU,
  UK: OFFICES.UK,
  US: OFFICES.US,
});

const OFFICE_BY_CURRENCY = Object.freeze({
  AUD: OFFICE_BY_COUNTRY.AU,
  CAD: OFFICE_BY_COUNTRY.CA,
  GBP: OFFICE_BY_COUNTRY.GB,
  USD: OFFICE_BY_COUNTRY.US,
});

const STRIKETHROUGH_COUNTRIES = ['US'];

const VOYAGE_TYPES = Object.freeze({
  EXPEDITION: 'expedition',
  OCEAN: 'ocean',
  RIVER: 'river',
  RIVER_OCEAN_COMBO: 'river-ocean-combo',
});

export {
  CMS_OFFICE_CODE_BY_COUNTRY,
  COUNTRY,
  COUNTRY_BY_CURRENCY,
  CURRENCY_BY_COUNTRY,
  CURRENCY_SYMBOLS,
  DATE_BY_COUNTRY,
  ISO_CODES_BY_COUNTRY,
  OFFICES,
  OFFICES_ALL,
  OFFICE_BY_COUNTRY,
  OFFICE_BY_CURRENCY,
  STRIKETHROUGH_COUNTRIES,
  SUPPORT_BY_COUNTRY,
  VOYAGE_TYPES,
};
