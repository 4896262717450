import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE } from 'common/auth/AUTH_CONSTANTS';
import { IS_LOCAL_AUTH_LOGGING_ENABLED } from 'constants/LOGGING';
import { logError, logInfo } from 'utils/dummyLogging';
import { localLogger } from 'utils/logging';

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    clearAuth: () => INITIAL_STATE,
    setAuth: (state, action) => {
      let newState = {};
      try {
        const { accountStatus, agency, agent, callingFunction, msal, token } = action.payload || {};
        localLogger(`authSlice.js - setAuth called from: ${callingFunction}`, IS_LOCAL_AUTH_LOGGING_ENABLED);
        newState = {
          ...state,
          ...action.payload,
          accountStatus: accountStatus || state.accountStatus,
          agency: { ...state.agency, ...agency },
          agent: { ...state.agent, ...agent },
          msal: { ...state.msal, ...msal },
          token: token || state.token,
        };
        logInfo('authSlice - setAuth - success');
      } catch (error) {
        logError('authSlice - setAuth', error);
      }
      return newState;
    },
  },
});

export const { clearAuth, setAuth, setAuthAccountModalRequired } = authSlice.actions;

export default authSlice;
