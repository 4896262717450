import { createSlice } from '@reduxjs/toolkit';
import { getFilteredKey } from 'utils/bookings';

const initialState = {
  filteredBookings: {},
};

const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    clearFilteredBookings: (state) => {
      return {
        ...state,
        filteredBookings: {},
      };
    },
    setFilteredBookings: (state, action) => {
      return {
        ...state,
        filteredBookings: {
          ...state.filteredBookings,
          [getFilteredKey(action.payload.filter)]: action.payload.data,
        },
      };
    },
  },
});

export const { clearFilteredBookings, setFilteredBookings } = bookingsSlice.actions;

export default bookingsSlice;
