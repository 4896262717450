import { BASE_PATH } from 'constants/API';
import { isEnabled } from 'constants/MOCK_CONFIG';
import { setupWorker } from 'msw/browser';
import { logError, logWarning } from 'utils/logging';
import handlers from './handlers';

const REQUEST_IGNORE_PATHS = [
  '.png',
  'applicationinsights.azure.com',
  'chrome-extension',
  'favicon.ico',
  'hot-update.js',
  'https://cms-assets',
  'https://cognito',
  'https://tap-api',
  'liveperson.net',
  'locales',
  'main.',
  'manifest.json',
  'static',
];

const init = async () => {
  try {
    if (isEnabled && process.env.NODE_ENV !== 'production') {
      if (!window.location.pathname.includes(`${BASE_PATH}/`)) {
        window.location.pathname = `${BASE_PATH}/`;
        return;
      }

      const worker = setupWorker(...handlers);
      await worker.start({
        quiet: true,
        onUnhandledRequest(req) {
          if (!REQUEST_IGNORE_PATHS.find((path) => req.url.includes(path))) {
            const warning = `Warning: captured a request without a matching request handler:
        • ${req.method} ${req.url}
        If you still wish to intercept this unhandled request, please create a request handler for it.
        Read more: https://mswjs.io/docs/getting-started/mocks
        `;
            logWarning(warning);
          }
        },
        serviceWorker: {
          url: `${BASE_PATH}/mockServiceWorker.js`,
        },
      });
    }
  } catch (error) {
    logError(error);
  }
};

export { init };
