import { API_PATHS } from 'constants/API';
import tapApi from './api';

const cmsApi = tapApi.injectEndpoints({
  endpoints: (build) => ({
    getCms: build.query({
      query: async ({ cmsPath, office }) => {
        if (cmsPath.url) {
          return cmsPath.url;
        }
        const path = await API_PATHS.cms({ path: cmsPath.path, office });
        return path;
      },
    }),
  }),
});

const {
  useGetCmsQuery,
  useLazyGetCmsQuery,
  usePrefetch: useGetCmsPrefetch,
  endpoints: { getCms },
} = cmsApi;

const { useQueryState: useGetCmsQueryState } = getCms;

export { getCms, useGetCmsPrefetch, useGetCmsQuery, useGetCmsQueryState, useLazyGetCmsQuery };
