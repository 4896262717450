// Note: This should only be used outside of the Redux Provider and is the error shown when all others fail.
// Use PageError or Error for all other cases.
import Error from 'common/error/Error';
import { SUPPORT_BY_COUNTRY } from 'constants/COUNTRIES';
import { PAGE_ERROR_DESCRIPTION } from 'constants/STRINGS';
import PropTypes from 'prop-types';

const RootError = ({ error, resetErrorBoundary }) => {
  return (
    <Error
      description={PAGE_ERROR_DESCRIPTION?.replace('[EMAIL_SUPPORT]', SUPPORT_BY_COUNTRY.US.email)}
      error={error}
      resetErrorBoundary={resetErrorBoundary}
    />
  );
};

RootError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default RootError;
