import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  redirectToPage: '',
  isRedirectModalEnabled: false,
};

const redirectSlice = createSlice({
  name: 'redirect',
  initialState,
  reducers: {
    setRedirectPage: (state, action) => {
      return {
        ...state,
        redirectToPage: action.payload,
      };
    },
    setIsRedirectModalEnabled: (state, action) => {
      return {
        ...state,
        isRedirectModalEnabled: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRedirectPage, setIsRedirectModalEnabled } = redirectSlice.actions;

export default redirectSlice;
