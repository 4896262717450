import { TAP_API_BASE_URL } from 'constants/ENV';
import { ACCOUNT_ERROR_ENABLED, DELAY } from 'constants/MOCK_CONFIG';
import { HttpResponse, delay, http } from 'msw';

const handlers = [];

if (ACCOUNT_ERROR_ENABLED) {
  const agentUrl = `${TAP_API_BASE_URL}/agency/*`;

  handlers.push(
    http.get(agentUrl, async () => {
      await delay(DELAY);
      return new HttpResponse(null, {
        status: 409,
        statusText: 'Conflict',
      });
    })
  );
}

export default handlers;
