import { BOOKING_STATUS } from 'constants/BOOKING';
import { unparse } from 'papaparse';
import { createDownloadLinkCompressed } from './file';
import { VALIDATION_REGEXES } from './formValidation';

const bookingsFilter = ({ bookings, isFilteredByBalanceDue, search, statuses = [] }) => {
  const isBookingNumber = VALIDATION_REGEXES.BOOKING_NUMBER.test(search);
  const isPastPaxNumber = VALIDATION_REGEXES.MOCK_PAST_PASSENGER.test(search);
  return bookings?.filter((booking) => {
    const bookingStatus = booking.priceDetails?.[0]?.bookingStatus;
    const hasStatus = statuses.length > 0 ? statuses.map(({ code }) => code).includes(bookingStatus) : true;
    if (!hasStatus) {
      return false;
    }
    if (isFilteredByBalanceDue) {
      return booking.priceDetails[0].balanceAmt > 0;
    }
    if (bookingStatus === BOOKING_STATUS.FNL.code && new Date(booking.embarkDate) < new Date()) {
      return false;
    }
    if (!search) {
      return true;
    }
    if (isBookingNumber) {
      return booking.bookingNo.toString() === search;
    }
    if (isPastPaxNumber) {
      return (booking.passengers || []).some((pax) => pax.cchid === search);
    }
    if ((booking.groupNo || '').toUpperCase().trim() === search) {
      return true;
    }
    const searchParts = search.split(' ').map((v) => (v || '').toUpperCase());
    return (booking?.priceDetails?.[0]?.passengers || []).some((pax) =>
      searchParts.every((part) => `${pax.firstName || ''} ${pax.lastName || ''}`.toUpperCase().includes(part))
    );
  });
};

const getBookingCsv = ({ bookings }) => {
  const csvFriendlyJson = bookings.map((booking) => {
    return {
      'Booking Number': booking.bookingNo,
      'Group Number': booking.groupNo.trim(),
      Status: BOOKING_STATUS[booking.priceDetails[0].bookingStatus].name,
      'Guest 1 First Name': booking.priceDetails[0].passengers[0].firstName,
      'Guest 1 Middle Name': booking.priceDetails[0].passengers[0].middleName,
      'Guest 1 Last Name': booking.priceDetails[0].passengers[0].lastName,
      'Guest 2 First Name': booking.priceDetails[0].passengers?.[1]?.firstName,
      'Guest 2 Middle Name': booking.priceDetails[0].passengers?.[1]?.middleName,
      'Guest 2 Last Name': booking.priceDetails[0].passengers?.[1]?.lastName,
      'Guest Form Complete': booking.completedGIF === 'Y',
      'Cruise Name': booking.voyageName,
      From: booking.embarkPort.name,
      To: booking.disembarkPort.name,
      'Sail Date': booking.embarkDate,
      'Deposit Due': booking.priceDetails[0].depositDue,
      'Deposit Due Date': booking.priceDetails[0].depDueDate,
      'Balance Due': booking.priceDetails[0].balanceAmt,
      'Balance Due Date': booking.priceDetails[0].balDueDate,
      Currency: booking.priceDetails[0].currency,
      Commission: booking.priceDetails[0].commission,
      'Gross Price': booking.priceDetails[0].grossPrice,
      'Paid To Date': booking.priceDetails[0].paidToDate,
    };
  });

  const csv = unparse(csvFriendlyJson);
  createDownloadLinkCompressed({
    data: csv,
    extension: 'csv',
    title: `Bookings-${new Date().toISOString().substring(0, 10)}`,
  });
};

const getFilteredKey = (filter) => `${filter.status}${filter.search ? `_${filter.search}` : ''}`;

export { bookingsFilter, getBookingCsv, getFilteredKey };
