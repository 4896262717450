import * as fflate from 'fflate';

const createDownloadLinkCompressed = async ({ data, extension, title }) => {
  const compressedFile = new Blob([
    fflate.zipSync({
      [`${title}.${extension}`]: fflate.strToU8(data),
    }),
  ]);
  const href = URL.createObjectURL(compressedFile);
  const downloadLink = document.createElement('a');
  downloadLink.href = href;
  downloadLink.download = `${title}.zip`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export { createDownloadLinkCompressed };
