const waitForElementToLoad = ({ selector }) => {
  return new Promise((resolve) => {
    let element = document.querySelector(selector);
    if (element) {
      resolve(element);
    }
    const observer = new MutationObserver(() => {
      element = document.querySelector(selector);
      if (element) {
        resolve(element);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      characterData: true,
      childList: true,
      subtree: true,
    });
  });
};

const addLoader = () => {
  document.body.classList.add('loading');
};

const removeLoader = () => {
  document.getElementById('root').style.display = 'flex';
  document.body.classList.remove('loading');
};

// HACK: If loading layout takes more than MAX_LOADING_MILLISECONDS, then force reload.
const removeLoaderWithTimer = async () => {
  const MAX_LOADING_MILLISECONDS = 30000; // 30 seconds
  let isLayoutFound = false;
  setTimeout(() => {
    if (!isLayoutFound) {
      window.location.reload(true);
    }
  }, MAX_LOADING_MILLISECONDS);
  await waitForElementToLoad({ selector: '.layout-container .main' });
  removeLoader();
  isLayoutFound = true;
};

export { addLoader, removeLoader, removeLoaderWithTimer, waitForElementToLoad };
