import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { appInsightsHelper } from '@viking-eng/telemetry';
import { AUTH_ERRORS } from 'common/auth/AUTH_CONSTANTS';
import { BASE_PATH, MAX_RETRIES, UNHANDLED_ENDPOINTS, USER_CANCELED_STATUSES } from 'constants/API';
import { IS_APP_INSIGHTS_ENABLED, TAP_API_BASE_URL } from 'constants/ENV';
import FEATURE_FLAGS from 'constants/FEATURE_FLAGS';
import ROUTES from 'constants/ROUTES';
import { getToken } from 'utils/auth';
import { logError, logInfo, logWarn } from 'utils/dummyLogging';
import { getRequestHeaders } from 'utils/httpsUtils';
import { getRouteFromPathname } from 'utils/routing';
import { base64Decode } from 'utils/string';

const TAG_TYPES = Object.freeze({
  cms: 'CMS',
});

const baseQuery = fetchBaseQuery({
  baseUrl: TAP_API_BASE_URL,
  prepareHeaders: async (headers, api) => {
    try {
      const { auth } = api.getState();
      const tokenResponse = await getToken();
      const headerObject = getRequestHeaders({
        appInsightsSessionId: IS_APP_INSIGHTS_ENABLED ? appInsightsHelper?.getSessionInfo()?.session?.id || '' : '',
        auth,
        token: tokenResponse?.accessToken,
      });
      Object.keys(headerObject).forEach((key) => headers.set(key, headerObject[key]));
    } catch (error) {
      if (error.message !== AUTH_ERRORS.IS_USER_NOT_SIGNED_IN) {
        logError('app/services/tap/api.js - baseQuery', error);
      } else {
        logWarn('app/services/tap/api.js - baseQuery - expected error', error);
      }
    }
    return headers;
  },
});

const baseQueryWithTelemetry = async (args, api, extraOptions) => {
  let result;
  try {
    let newArgs = args.then ? await args : args;
    if (newArgs.url) {
      newArgs.url = newArgs.url.includes('http') ? newArgs.url : `${TAP_API_BASE_URL}/${newArgs.url}`;
    } else {
      newArgs = newArgs?.includes('http') ? newArgs : `${TAP_API_BASE_URL}/${newArgs}`;
    }

    result = await baseQuery(newArgs, api, extraOptions);

    const startDate = new Date();
    const appInsightsContextSessionId = IS_APP_INSIGHTS_ENABLED
      ? appInsightsHelper?.getSessionInfo()?.session?.id || ''
      : '';
    const traceparent = result.meta.request.headers.get('traceparent');
    let requestGroupId = '';
    let appInsightsHeader = '';
    let appInsightsHeaderDecoded = '';
    try {
      appInsightsHeader = result.meta.request.headers.get('appinsights');
      if (appInsightsHeader !== null) {
        appInsightsHeaderDecoded = base64Decode(appInsightsHeader);
        requestGroupId = JSON.parse(appInsightsHeaderDecoded)?.requestGroupId;
      }
    } catch (err) {
      logInfo('app/services/tap/api.js - baseQueryWithTelemetry - headers', {
        appInsightsHeader,
        appInsightsHeaderDecoded,
        err,
        requestGroupId,
      });
    }

    if (appInsightsHelper && IS_APP_INSIGHTS_ENABLED) {
      appInsightsHelper.handleTelemetry({
        duration: Math.abs(startDate - new Date()),
        id: traceparent,
        options: { method: result.meta.request.method },
        requestGroupId,
        resource: newArgs.url || newArgs,
        response: {
          data: result.data || {},
          headers: result.meta.request.headers,
          json: () => Promise.resolve(result.data),
          ok: result.error ? false : result.meta.response.status === 200,
          status: result.error ? result.error.status : result.meta.response.status,
          statusText: result.error ? result.error.error : result.meta.response.statusText,
          url: newArgs.url || newArgs,
        },
        sessionId: appInsightsContextSessionId,
      });
    }
  } catch (error) {
    logError('app/services/tap/api.js - baseQueryWithTelemetry - handleTelemetry', error);
  }
  if (UNHANDLED_ENDPOINTS.includes(api?.endpoint)) {
    if ([400, 404].includes(result.error?.status)) {
      retry.fail(result.error);
    }
  } else if (USER_CANCELED_STATUSES.includes(result.error?.status)) {
    // Do not retry if the call was interrupted by user
    retry.fail(result.error);
  }
  return result;
};

const backoffMaxTryHandler = (attempt = 0, maxRetries = MAX_RETRIES) => {
  const route = getRouteFromPathname();
  if (attempt === maxRetries && !route.isPublic && FEATURE_FLAGS.isApiMaxTryErrorHandlerEnabled) {
    window.location.href = `${BASE_PATH}${ROUTES.pageError.url}`;
  }
};

const baseQueryWithRetry = retry(baseQueryWithTelemetry, {
  backoff: backoffMaxTryHandler,
  maxRetries: MAX_RETRIES,
});

const api = createApi({
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({}),
  reducerPath: 'tapApi',
  tagTypes: Object.values(TAG_TYPES),
});

export default api;
