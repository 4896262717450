import useHistory from 'hooks/useHistory';
import PropTypes from 'prop-types';
import { useLayoutEffect } from 'react';
import { logError } from 'utils/logging';
import ErrorPrimitive from './ErrorPrimitive';

const Error = ({ children, className, description, error, resetErrorBoundary, title }) => {
  const history = useHistory();

  useLayoutEffect(() => {
    if (history.lastPathName !== history.previousPage) {
      if (resetErrorBoundary && typeof resetErrorBoundary === 'function') {
        resetErrorBoundary();
      } else {
        logError({ error, description, title });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, resetErrorBoundary]);

  // To show something less disruptive like a toast or not show any ErrorBoundary at all, we can add that functionality here.
  // The error will always be logged regardless and developers will always see the error overlay.
  // error.message below is determined by how showBoundary is called in other places, so these are just examples.
  // useEffect(() => {
  //   switch (error.message) {
  //     case 'disableErrorBoundary':
  //       resetErrorBoundary(); // Don't show error at all
  //       break;
  //     case 'showToast':
  //       // Add toast functionality
  //       break;
  //     default:
  //       break;
  //   }
  // }, [error, resetErrorBoundary]);

  if (!children && !description && !title) {
    throw new Error('Error component requires one of the following: children, description, and title.');
  }

  return (
    <ErrorPrimitive className={className} description={description} title={title}>
      {children}
    </ErrorPrimitive>
  );
};

Error.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({}),
  resetErrorBoundary: PropTypes.func,
  title: PropTypes.string,
};

export default Error;
