const getCookie = (name) => {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${name}=`))
    ?.split('=')[1];
};

const setCookie = ({ name, value, expireDays = 0 }) => {
  const date = new Date();
  date.setDate(date.getDate() + expireDays);
  const cookie = `${name}=${value}; expires=${date.toUTCString()};`;
  document.cookie = cookie;
};

const removeCookie = (name) => {
  const value = getCookie(name);
  if (value) {
    document.cookie = `${name}=${value};expires=0;`;
  }
};

const removeAllCookies = () => {
  document.cookie.split(';').forEach((cookie) => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  });
};

export { getCookie, removeAllCookies, removeCookie, setCookie };
