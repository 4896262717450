import { AUTH_ERROR_ENABLED, DELAY } from 'constants/MOCK_CONFIG';
import { HttpResponse, delay, http } from 'msw';

const handlers = [];

if (AUTH_ERROR_ENABLED) {
  handlers.push(
    http.get('*login.ninerealms.tech/*', async () => {
      await delay(DELAY);
      return new HttpResponse(null, {
        status: 409,
        statusText: 'Conflict',
      });
    })
  );
}

export default handlers;
