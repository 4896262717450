const fallbackCspWhitelist =
  // eslint-disable-next-line max-len
  'assets.adobedtm.com cdn.cookielaw.org cdn.pdst.fm connect.facebook.net *.privacymanager.io *.liveperson.net player.vimeo.com s.yimg.com s.pinimg.com solutions.invocacdn.com www.googletagmanager.com ct.pinterest.com googleads.g.doubleclick.net';

const insertCspMetaTag = (nonce) => {
  try {
    const scriptSrcContent = nonce
      ? `'self' 'nonce-${nonce}' 'strict-dynamic' ${fallbackCspWhitelist}`
      : `'self' ${fallbackCspWhitelist}`;
    let newCspMetaTag = document.getElementById('metaCsp');
    if (!newCspMetaTag) {
      newCspMetaTag = document.createElement('meta');
      newCspMetaTag.id = 'metaCsp';
      newCspMetaTag.httpEquiv = 'Content-Security-Policy';
      newCspMetaTag.content = `object-src 'none'; script-src ${scriptSrcContent}; base-uri 'self'`;
      document.head.prepend(newCspMetaTag);
    }
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.warn(ex);
  }
};

export { insertCspMetaTag };
