import { BrowserUtils, PublicClientApplication } from '@azure/msal-browser';
import { AUTH_ERRORS } from 'common/auth/AUTH_CONSTANTS';
import NavigationClient from 'common/auth/NavigationClient';
import { b2cPolicies, msalConfig } from 'configs/authConfig';
import { BASE_PATH } from 'constants/API';
import { B2C_DOMAIN } from 'constants/ENV';
import ROUTES from 'constants/ROUTES';
import { logError, logInfo } from './dummyLogging';

const msalInstance = new PublicClientApplication(msalConfig);

const addEventCallback = (callback) => {
  try {
    msalInstance.addEventCallback(callback);
    logInfo('utils/auth - addEventCallback - success');
  } catch (error) {
    logError('utils/auth - addEventCallback', error);
  }
};

const changeEmail = async () => {
  try {
    await msalInstance.acquireTokenRedirect(b2cPolicies.authorities.changeUserName);
    logInfo('utils/auth - changeEmail - success');
  } catch (error) {
    logError('utils/auth - changeEmail', error);
  }
};

const changePassword = async () => {
  try {
    await msalInstance.acquireTokenRedirect(b2cPolicies.authorities.changePassword);
    logInfo('utils/auth - changePassword - success');
  } catch (error) {
    logError('utils/auth - changePassword', error);
  }
};

const clearCache = async () => {
  try {
    await msalInstance.clearCache();
    logInfo('utils/auth - clearCache - success');
  } catch (error) {
    logError('utils/auth - clearCache', error);
  }
};

const enableAccountStorageEvents = () => {
  try {
    msalInstance.enableAccountStorageEvents();
    logInfo('utils/auth - enableAccountStorageEvents - success');
  } catch (error) {
    logError('utils/auth - enableAccountStorageEvents', error);
  }
};

const getActiveAccount = () => {
  let account;
  try {
    account = msalInstance.getActiveAccount();
    logInfo('utils/auth - getActiveAccount - success');
  } catch (error) {
    logError('utils/auth - getActiveAccount', error);
  }
  return account;
};

const getAccount = () => {
  let account;
  try {
    const activeAccount = msalInstance.getActiveAccount();
    if (activeAccount?.environment === B2C_DOMAIN) {
      account = activeAccount;
    } else {
      account = msalInstance.getAllAccounts()?.find(({ environment }) => environment === B2C_DOMAIN);
    }
    logInfo('utils/auth - getAccount - success');
  } catch (error) {
    logError('utils/auth - getAccount', error);
  }
  return account;
};

const getToken = async ({ authority } = {}) => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();
  if (!activeAccount && accounts.length === 0) {
    throw new Error(AUTH_ERRORS.IS_USER_NOT_SIGNED_IN);
  }
  const request = {
    ...msalConfig,
    account: activeAccount || accounts?.[0],
  };
  if (authority) {
    request.authority = authority;
  }
  const tokenResponse = await msalInstance.acquireTokenSilent(request);
  logInfo('utils/auth - getToken - success');
  return tokenResponse;
};

const handleRedirectPromise = async () => {
  try {
    await msalInstance.handleRedirectPromise();
    logInfo('utils/auth - handleRedirectPromise - success');
  } catch (error) {
    logError('utils/auth - handleRedirectPromise', error);
  }
};

const initialize = async () => {
  try {
    await msalInstance.initialize();
    logInfo('utils/auth - initialize - success');
  } catch (error) {
    logError('utils/auth - initialize', error);
  }
  return msalInstance;
};

const integrateRouting = (navigate) => {
  try {
    const navigationClient = new NavigationClient(navigate);
    msalInstance.setNavigationClient(navigationClient);
    logInfo('utils/auth - integrateRouting - success');
  } catch (error) {
    logError('utils/auth - integrateRouting', error);
  }
};

const loginRedirect = (config = msalConfig) => {
  try {
    msalInstance.loginRedirect({
      ...config,
      redirectStartPage: `${BASE_PATH}${ROUTES.home.url}`,
    });
    logInfo('utils/auth - loginRedirect - success');
  } catch (error) {
    logError('utils/auth - loginRedirect', error);
  }
};

const logoutRedirect = async () => {
  try {
    await msalInstance.logoutRedirect({
      account: getActiveAccount(),
      onRedirectNavigate: () => {
        return !BrowserUtils.isInIframe();
      },
    });
    logInfo('utils/auth - logoutRedirect - success');
  } catch (error) {
    logError('utils/auth - logoutRedirect', error);
  }
};

const setActiveAccount = ({ account }) => {
  try {
    msalInstance.setActiveAccount(account);
    logInfo('utils/auth - getActiveAccount - success');
  } catch (error) {
    logError('utils/auth - getActiveAccount', error);
  }
};

const ssoSilent = () => {
  try {
    msalInstance.ssoSilent(msalConfig);
    logInfo('utils/auth - ssoSilent - success');
  } catch (error) {
    logError('utils/auth - ssoSilent', error);
  }
};

export {
  addEventCallback,
  changeEmail,
  changePassword,
  clearCache,
  enableAccountStorageEvents,
  getAccount,
  getActiveAccount,
  getToken,
  handleRedirectPromise,
  initialize,
  integrateRouting,
  loginRedirect,
  logoutRedirect,
  setActiveAccount,
  ssoSilent,
};
