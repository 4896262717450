// Note: Run tap-api/scripts/i18n/countryUtils.js to validate and correct data.

const EVO_COUNTRY = {
  AA: {
    code: 'AA',
    isEnabled: false,
    iso3: 'AAF',
    isoName: '',
    name: 'Armed Forces',
  },
  AD: {
    code: 'AD',
    isEnabled: true,
    iso3: 'AND',
    isoName: 'Andorra',
    name: 'Andorra',
  },
  AE: {
    code: 'AE',
    isEnabled: true,
    iso3: 'ARE',
    isoName: 'United Arab Emirates',
    name: 'United Arab Emirates',
  },
  AF: {
    code: 'AF',
    isEnabled: true,
    iso3: 'AFG',
    isoName: 'Afghanistan',
    name: 'Afghanistan',
  },
  AG: {
    code: 'AG',
    isEnabled: true,
    iso3: 'ATG',
    isoName: 'Antigua and Barbuda',
    name: 'Antigua and Barbuda',
  },
  AI: {
    code: 'AI',
    isEnabled: true,
    iso3: 'AIA',
    isoName: 'Anguilla',
    name: 'Anguilla',
  },
  AL: {
    code: 'AL',
    isEnabled: true,
    iso3: 'ALB',
    isoName: 'Albania',
    name: 'Albania',
  },
  AM: {
    code: 'AM',
    isEnabled: true,
    iso3: 'ARM',
    isoName: 'Armenia',
    name: 'Armenia',
  },
  AN: {
    code: 'AN',
    isEnabled: false,
    iso3: 'ANT',
    isoName: '',
    name: 'Netherlands Antilles',
  },
  AO: {
    code: 'AO',
    isEnabled: true,
    iso3: 'AGO',
    isoName: 'Angola',
    name: 'Angola',
  },
  AQ: {
    code: 'AQ',
    isEnabled: true,
    iso3: 'ATA',
    isoName: 'Antarctica',
    name: 'Antarctica',
  },
  AR: {
    code: 'AR',
    isEnabled: true,
    iso3: 'ARG',
    isoName: 'Argentina',
    name: 'Argentina',
  },
  AS: {
    code: 'AS',
    isEnabled: true,
    iso3: 'ASM',
    isoName: 'American Samoa',
    name: 'American Samoa',
  },
  AT: {
    code: 'AT',
    isEnabled: true,
    iso3: 'AUT',
    isoName: 'Austria',
    name: 'Austria',
  },
  AU: {
    code: 'AU',
    isEnabled: true,
    iso3: 'AUS',
    isoName: 'Australia',
    name: 'Australia',
  },
  AW: {
    code: 'AW',
    isEnabled: true,
    iso3: 'ABW',
    isoName: 'Aruba',
    name: 'Aruba',
  },
  AX: {
    code: 'AX',
    isEnabled: true,
    iso3: 'ALA',
    isoName: 'Åland Islands',
    name: 'Aland Islands',
  },
  AZ: {
    code: 'AZ',
    isEnabled: true,
    iso3: 'AZE',
    isoName: 'Azerbaijan',
    name: 'Azerbaijan',
  },
  BA: {
    code: 'BA',
    isEnabled: true,
    iso3: 'BIH',
    isoName: 'Bosnia and Herzegovina',
    name: 'Bosnia and Herzegowina',
  },
  BB: {
    code: 'BB',
    isEnabled: true,
    iso3: 'BRB',
    isoName: 'Barbados',
    name: 'Barbados',
  },
  BD: {
    code: 'BD',
    isEnabled: true,
    iso3: 'BGD',
    isoName: 'Bangladesh',
    name: 'Bangladesh',
  },
  BE: {
    code: 'BE',
    isEnabled: true,
    iso3: 'BEL',
    isoName: 'Belgium',
    name: 'Belgium',
  },
  BF: {
    code: 'BF',
    isEnabled: true,
    iso3: 'BFA',
    isoName: 'Burkina Faso',
    name: 'Burkina Faso',
  },
  BG: {
    code: 'BG',
    isEnabled: true,
    iso3: 'BGR',
    isoName: 'Bulgaria',
    name: 'Bulgaria',
  },
  BH: {
    code: 'BH',
    isEnabled: true,
    iso3: 'BHR',
    isoName: 'Bahrain',
    name: 'Bahrain',
  },
  BI: {
    code: 'BI',
    isEnabled: true,
    iso3: 'BDI',
    isoName: 'Burundi',
    name: 'Burundi',
  },
  BJ: {
    code: 'BJ',
    isEnabled: true,
    iso3: 'BEN',
    isoName: 'Benin',
    name: 'Benin',
  },
  BL: {
    code: 'BL',
    isEnabled: true,
    iso3: 'BLM',
    isoName: 'Saint Barthélemy',
    name: 'St. Barts',
  },
  BM: {
    code: 'BM',
    isEnabled: true,
    iso3: 'BMU',
    isoName: 'Bermuda',
    name: 'Bermuda',
  },
  BN: {
    code: 'BN',
    isEnabled: true,
    iso3: 'BRN',
    isoName: 'Brunei Darussalam',
    name: 'Brunei Darussalam',
  },
  BO: {
    code: 'BO',
    isEnabled: true,
    iso3: 'BOL',
    isoName: 'Bolivia',
    name: 'Bolivia',
  },
  BQ: {
    code: 'BQ',
    isEnabled: true,
    iso3: 'BES',
    isoName: 'Bonaire, Sint Eustatius and Saba',
    name: 'Bonaire, Sint Eustatius and Saba',
  },
  BR: {
    code: 'BR',
    isEnabled: true,
    iso3: 'BRA',
    isoName: 'Brazil',
    name: 'Brazil',
  },
  BS: {
    code: 'BS',
    isEnabled: true,
    iso3: 'BHS',
    isoName: 'Bahamas',
    name: 'Bahamas',
  },
  BT: {
    code: 'BT',
    isEnabled: true,
    iso3: 'BTN',
    isoName: 'Bhutan',
    name: 'Bhutan',
  },
  BV: {
    code: 'BV',
    isEnabled: true,
    iso3: 'BVT',
    isoName: 'Bouvet Island',
    name: 'Bouvet Island',
  },
  BW: {
    code: 'BW',
    isEnabled: true,
    iso3: 'BWA',
    isoName: 'Botswana',
    name: 'Botswana',
  },
  BY: {
    code: 'BY',
    isEnabled: true,
    iso3: 'BLR',
    isoName: 'Belarus',
    name: 'Belarus',
  },
  BZ: {
    code: 'BZ',
    isEnabled: true,
    iso3: 'BLZ',
    isoName: 'Belize',
    name: 'Belize',
  },
  CA: {
    code: 'CA',
    isEnabled: true,
    iso3: 'CAN',
    isoName: 'Canada',
    name: 'Canada',
  },
  CC: {
    code: 'CC',
    isEnabled: true,
    iso3: 'CCK',
    isoName: 'Cocos (Keeling) Islands',
    name: 'Cocos (Keeling) Islands',
  },
  CD: {
    code: 'CD',
    isEnabled: true,
    iso3: 'COD',
    isoName: 'Democratic Republic of the Congo',
    name: 'Congo, Democratic Republic of',
  },
  CF: {
    code: 'CF',
    isEnabled: true,
    iso3: 'CAF',
    isoName: 'Central African Republic',
    name: 'Central African Republic',
  },
  CG: {
    code: 'CG',
    isEnabled: true,
    iso3: 'COG',
    isoName: 'Republic of the Congo',
    name: 'Congo (Brazzaville)',
  },
  CH: {
    code: 'CH',
    isEnabled: true,
    iso3: 'CHE',
    isoName: 'Switzerland',
    name: 'Switzerland',
  },
  CI: {
    code: 'CI',
    isEnabled: true,
    iso3: 'CIV',
    isoName: "Cote d'Ivoire",
    name: 'Cote D Ivoire',
  },
  CK: {
    code: 'CK',
    isEnabled: true,
    iso3: 'COK',
    isoName: 'Cook Islands',
    name: 'Cook Islands',
  },
  CL: {
    code: 'CL',
    isEnabled: true,
    iso3: 'CHL',
    isoName: 'Chile',
    name: 'Chile',
  },
  CM: {
    code: 'CM',
    isEnabled: true,
    iso3: 'CMR',
    isoName: 'Cameroon',
    name: 'Cameroon',
  },
  CN: {
    code: 'CN',
    isEnabled: true,
    iso3: 'CHN',
    isoName: "People's Republic of China",
    name: 'China',
  },
  CO: {
    code: 'CO',
    isEnabled: true,
    iso3: 'COL',
    isoName: 'Colombia',
    name: 'Colombia',
  },
  CR: {
    code: 'CR',
    isEnabled: true,
    iso3: 'CRI',
    isoName: 'Costa Rica',
    name: 'Costa Rica',
  },
  CU: {
    code: 'CU',
    isEnabled: true,
    iso3: 'CUB',
    isoName: 'Cuba',
    name: 'Cuba',
  },
  CV: {
    code: 'CV',
    isEnabled: true,
    iso3: 'CPV',
    isoName: 'Cape Verde',
    name: 'Cape Verde',
  },
  CW: {
    code: 'CW',
    isEnabled: true,
    iso3: 'CUW',
    isoName: 'Curaçao',
    name: 'Curacao',
  },
  CX: {
    code: 'CX',
    isEnabled: true,
    iso3: 'CXR',
    isoName: 'Christmas Island',
    name: 'Christmas Island',
  },
  CY: {
    code: 'CY',
    isEnabled: true,
    iso3: 'CYP',
    isoName: 'Cyprus',
    name: 'Cyprus',
  },
  CZ: {
    code: 'CZ',
    isEnabled: true,
    iso3: 'CZE',
    isoName: 'Czech Republic',
    name: 'Czech Republic',
  },
  DE: {
    code: 'DE',
    isEnabled: true,
    iso3: 'DEU',
    isoName: 'Germany',
    name: 'Germany',
  },
  DJ: {
    code: 'DJ',
    isEnabled: true,
    iso3: 'DJI',
    isoName: 'Djibouti',
    name: 'Djibouti',
  },
  DK: {
    code: 'DK',
    isEnabled: true,
    iso3: 'DNK',
    isoName: 'Denmark',
    name: 'Denmark',
  },
  DM: {
    code: 'DM',
    isEnabled: true,
    iso3: 'DMA',
    isoName: 'Dominica',
    name: 'Dominica',
  },
  DO: {
    code: 'DO',
    isEnabled: true,
    iso3: 'DOM',
    isoName: 'Dominican Republic',
    name: 'Dominican Republic',
  },
  DZ: {
    code: 'DZ',
    isEnabled: true,
    iso3: 'DZA',
    isoName: 'Algeria',
    name: 'Algeria',
  },
  EC: {
    code: 'EC',
    isEnabled: true,
    iso3: 'ECU',
    isoName: 'Ecuador',
    name: 'Ecuador',
  },
  EE: {
    code: 'EE',
    isEnabled: true,
    iso3: 'EST',
    isoName: 'Estonia',
    name: 'Estonia',
  },
  EG: {
    code: 'EG',
    isEnabled: true,
    iso3: 'EGY',
    isoName: 'Egypt',
    name: 'Egypt',
  },
  EH: {
    code: 'EH',
    isEnabled: true,
    iso3: 'ESH',
    isoName: 'Western Sahara',
    name: 'Western Sahara',
  },
  ER: {
    code: 'ER',
    isEnabled: true,
    iso3: 'ERI',
    isoName: 'Eritrea',
    name: 'Eritrea',
  },
  ES: {
    code: 'ES',
    isEnabled: true,
    iso3: 'ESP',
    isoName: 'Spain',
    name: 'Spain',
  },
  ET: {
    code: 'ET',
    isEnabled: true,
    iso3: 'ETH',
    isoName: 'Ethiopia',
    name: 'Ethiopia',
  },
  FI: {
    code: 'FI',
    isEnabled: true,
    iso3: 'FIN',
    isoName: 'Finland',
    name: 'Finland',
  },
  FJ: {
    code: 'FJ',
    isEnabled: true,
    iso3: 'FJI',
    isoName: 'Fiji',
    name: 'Fiji',
  },
  FK: {
    code: 'FK',
    isEnabled: true,
    iso3: 'FLK',
    isoName: 'Falkland Islands (Malvinas)',
    name: 'Falkland Islands',
  },
  FM: {
    code: 'FM',
    isEnabled: true,
    iso3: 'FSM',
    isoName: 'Micronesia, Federated States of',
    name: 'Micronesia',
  },
  FO: {
    code: 'FO',
    isEnabled: true,
    iso3: 'FRO',
    isoName: 'Faroe Islands',
    name: 'Faroe Islands',
  },
  FR: {
    code: 'FR',
    isEnabled: true,
    iso3: 'FRA',
    isoName: 'France',
    name: 'France',
  },
  GA: {
    code: 'GA',
    isEnabled: true,
    iso3: 'GAB',
    isoName: 'Gabon',
    name: 'Gabon',
  },
  GB: {
    code: 'GB',
    isEnabled: true,
    iso3: 'GBR',
    isoName: 'United Kingdom',
    name: 'United Kingdom',
  },
  GD: {
    code: 'GD',
    isEnabled: true,
    iso3: 'GRD',
    isoName: 'Grenada',
    name: 'Grenada',
  },
  GE: {
    code: 'GE',
    isEnabled: true,
    iso3: 'GEO',
    isoName: 'Georgia',
    name: 'Georgia',
  },
  GF: {
    code: 'GF',
    isEnabled: true,
    iso3: 'GUF',
    isoName: 'French Guiana',
    name: 'French Guiana',
  },
  GG: {
    code: 'GG',
    isEnabled: true,
    iso3: 'GGY',
    isoName: 'Guernsey',
    name: 'Guernsey',
  },
  GH: {
    code: 'GH',
    isEnabled: true,
    iso3: 'GHA',
    isoName: 'Ghana',
    name: 'Ghana',
  },
  GI: {
    code: 'GI',
    isEnabled: true,
    iso3: 'GIB',
    isoName: 'Gibraltar',
    name: 'Gibraltar',
  },
  GL: {
    code: 'GL',
    isEnabled: true,
    iso3: 'GRL',
    isoName: 'Greenland',
    name: 'Greenland',
  },
  GM: {
    code: 'GM',
    isEnabled: true,
    iso3: 'GMB',
    isoName: 'Republic of The Gambia',
    name: 'Gambia',
  },
  GN: {
    code: 'GN',
    isEnabled: true,
    iso3: 'GIN',
    isoName: 'Guinea',
    name: 'Guinea',
  },
  GP: {
    code: 'GP',
    isEnabled: true,
    iso3: 'GLP',
    isoName: 'Guadeloupe',
    name: 'Guadeloupe',
  },
  GQ: {
    code: 'GQ',
    isEnabled: true,
    iso3: 'GNQ',
    isoName: 'Equatorial Guinea',
    name: 'Equatorial Guinea',
  },
  GR: {
    code: 'GR',
    isEnabled: true,
    iso3: 'GRC',
    isoName: 'Greece',
    name: 'Greece',
  },
  GS: {
    code: 'GS',
    isEnabled: true,
    iso3: 'SGS',
    isoName: 'South Georgia and the South Sandwich Islands',
    name: 'Georgia & South Sandwich Islands',
  },
  GT: {
    code: 'GT',
    isEnabled: true,
    iso3: 'GTM',
    isoName: 'Guatemala',
    name: 'Guatemala',
  },
  GU: {
    code: 'GU',
    isEnabled: true,
    iso3: 'GUM',
    isoName: 'Guam',
    name: 'Guam',
  },
  GW: {
    code: 'GW',
    isEnabled: true,
    iso3: 'GNB',
    isoName: 'Guinea-Bissau',
    name: 'Guinea-Bissau',
  },
  GY: {
    code: 'GY',
    isEnabled: true,
    iso3: 'GUY',
    isoName: 'Guyana',
    name: 'Guyana',
  },
  HK: {
    code: 'HK',
    isEnabled: true,
    iso3: 'HKG',
    isoName: 'Hong Kong',
    name: 'Hong Kong',
  },
  HM: {
    code: 'HM',
    isEnabled: true,
    iso3: 'HMD',
    isoName: 'Heard Island and McDonald Islands',
    name: 'Heard & Mc Donald Islands',
  },
  HN: {
    code: 'HN',
    isEnabled: true,
    iso3: 'HND',
    isoName: 'Honduras',
    name: 'Honduras',
  },
  HR: {
    code: 'HR',
    isEnabled: true,
    iso3: 'HRV',
    isoName: 'Croatia',
    name: 'Croatia',
  },
  HT: {
    code: 'HT',
    isEnabled: true,
    iso3: 'HTI',
    isoName: 'Haiti',
    name: 'Haiti',
  },
  HU: {
    code: 'HU',
    isEnabled: true,
    iso3: 'HUN',
    isoName: 'Hungary',
    name: 'Hungary',
  },
  ID: {
    code: 'ID',
    isEnabled: true,
    iso3: 'IDN',
    isoName: 'Indonesia',
    name: 'Indonesia',
  },
  IE: {
    code: 'IE',
    isEnabled: true,
    iso3: 'IRL',
    isoName: 'Ireland',
    name: 'Ireland',
  },
  IL: {
    code: 'IL',
    isEnabled: true,
    iso3: 'ISR',
    isoName: 'Israel',
    name: 'Israel',
  },
  IM: {
    code: 'IM',
    isEnabled: true,
    iso3: 'IMN',
    isoName: 'Isle of Man',
    name: 'Isle of Man',
  },
  IN: {
    code: 'IN',
    isEnabled: true,
    iso3: 'IND',
    isoName: 'India',
    name: 'India',
  },
  IO: {
    code: 'IO',
    isEnabled: true,
    iso3: 'IOT',
    isoName: 'British Indian Ocean Territory',
    name: 'British Indian Ocean Territory',
  },
  IQ: {
    code: 'IQ',
    isEnabled: true,
    iso3: 'IRQ',
    isoName: 'Iraq',
    name: 'Iraq',
  },
  IR: {
    code: 'IR',
    isEnabled: true,
    iso3: 'IRN',
    isoName: 'Islamic Republic of Iran',
    name: 'Iran',
  },
  IS: {
    code: 'IS',
    isEnabled: true,
    iso3: 'ISL',
    isoName: 'Iceland',
    name: 'Iceland',
  },
  IT: {
    code: 'IT',
    isEnabled: true,
    iso3: 'ITA',
    isoName: 'Italy',
    name: 'Italy',
  },
  JE: {
    code: 'JE',
    isEnabled: true,
    iso3: 'JEY',
    isoName: 'Jersey',
    name: 'Jersey',
  },
  JM: {
    code: 'JM',
    isEnabled: true,
    iso3: 'JAM',
    isoName: 'Jamaica',
    name: 'Jamaica',
  },
  JO: {
    code: 'JO',
    isEnabled: true,
    iso3: 'JOR',
    isoName: 'Jordan',
    name: 'Jordan',
  },
  JP: {
    code: 'JP',
    isEnabled: true,
    iso3: 'JPN',
    isoName: 'Japan',
    name: 'Japan',
  },
  KE: {
    code: 'KE',
    isEnabled: true,
    iso3: 'KEN',
    isoName: 'Kenya',
    name: 'Kenya',
  },
  KG: {
    code: 'KG',
    isEnabled: true,
    iso3: 'KGZ',
    isoName: 'Kyrgyzstan',
    name: 'Kyrgyzstan',
  },
  KH: {
    code: 'KH',
    isEnabled: true,
    iso3: 'KHM',
    isoName: 'Cambodia',
    name: 'Cambodia',
  },
  KI: {
    code: 'KI',
    isEnabled: true,
    iso3: 'KIR',
    isoName: 'Kiribati',
    name: 'Kiribati',
  },
  KM: {
    code: 'KM',
    isEnabled: true,
    iso3: 'COM',
    isoName: 'Comoros',
    name: 'Comoros',
  },
  KN: {
    code: 'KN',
    isEnabled: true,
    iso3: 'KNA',
    isoName: 'Saint Kitts and Nevis',
    name: 'Saint Kitts & Nevis',
  },
  KP: {
    code: 'KP',
    isEnabled: true,
    iso3: 'PRK',
    isoName: 'North Korea',
    name: 'Korea (North)',
  },
  KR: {
    code: 'KR',
    isEnabled: true,
    iso3: 'KOR',
    isoName: 'South Korea',
    name: 'Korea (South)',
  },
  KW: {
    code: 'KW',
    isEnabled: true,
    iso3: 'KWT',
    isoName: 'Kuwait',
    name: 'Kuwait',
  },
  KY: {
    code: 'KY',
    isEnabled: true,
    iso3: 'CYM',
    isoName: 'Cayman Islands',
    name: 'Cayman Islands',
  },
  KZ: {
    code: 'KZ',
    isEnabled: true,
    iso3: 'KAZ',
    isoName: 'Kazakhstan',
    name: 'Kazakhstan',
  },
  LA: {
    code: 'LA',
    isEnabled: true,
    iso3: 'LAO',
    isoName: "Lao People's Democratic Republic",
    name: 'Laos',
  },
  LB: {
    code: 'LB',
    isEnabled: true,
    iso3: 'LBN',
    isoName: 'Lebanon',
    name: 'Lebanon',
  },
  LC: {
    code: 'LC',
    isEnabled: true,
    iso3: 'LCA',
    isoName: 'Saint Lucia',
    name: 'Saint Lucia',
  },
  LI: {
    code: 'LI',
    isEnabled: true,
    iso3: 'LIE',
    isoName: 'Liechtenstein',
    name: 'Liechtenstein',
  },
  LK: {
    code: 'LK',
    isEnabled: true,
    iso3: 'LKA',
    isoName: 'Sri Lanka',
    name: 'Sri Lanka',
  },
  LR: {
    code: 'LR',
    isEnabled: true,
    iso3: 'LBR',
    isoName: 'Liberia',
    name: 'Liberia',
  },
  LS: {
    code: 'LS',
    isEnabled: true,
    iso3: 'LSO',
    isoName: 'Lesotho',
    name: 'Lesotho',
  },
  LT: {
    code: 'LT',
    isEnabled: true,
    iso3: 'LTU',
    isoName: 'Lithuania',
    name: 'Lithuania',
  },
  LU: {
    code: 'LU',
    isEnabled: true,
    iso3: 'LUX',
    isoName: 'Luxembourg',
    name: 'Luxembourg',
  },
  LV: {
    code: 'LV',
    isEnabled: true,
    iso3: 'LVA',
    isoName: 'Latvia',
    name: 'Latvia',
  },
  LY: {
    code: 'LY',
    isEnabled: true,
    iso3: 'LBY',
    isoName: 'Libya',
    name: 'Libya',
  },
  MA: {
    code: 'MA',
    isEnabled: true,
    iso3: 'MAR',
    isoName: 'Morocco',
    name: 'Morocco',
  },
  MC: {
    code: 'MC',
    isEnabled: true,
    iso3: 'MCO',
    isoName: 'Monaco',
    name: 'Monaco',
  },
  MD: {
    code: 'MD',
    isEnabled: true,
    iso3: 'MDA',
    isoName: 'Moldova, Republic of',
    name: 'Moldova',
  },
  ME: {
    code: 'ME',
    isEnabled: true,
    iso3: 'MNE',
    isoName: 'Montenegro',
    name: 'Montenegro',
  },
  MF: {
    code: 'MF',
    isEnabled: true,
    iso3: 'MAF',
    isoName: 'Saint Martin (French part)',
    name: 'Saint Martin',
  },
  MG: {
    code: 'MG',
    isEnabled: true,
    iso3: 'MDG',
    isoName: 'Madagascar',
    name: 'Madagascar',
  },
  MH: {
    code: 'MH',
    isEnabled: true,
    iso3: 'MHL',
    isoName: 'Marshall Islands',
    name: 'Marshall Islands',
  },
  MK: {
    code: 'MK',
    isEnabled: true,
    iso3: 'MKD',
    isoName: 'The Republic of North Macedonia',
    name: 'Macedonia',
  },
  ML: {
    code: 'ML',
    isEnabled: true,
    iso3: 'MLI',
    isoName: 'Mali',
    name: 'Mali',
  },
  MM: {
    code: 'MM',
    isEnabled: true,
    iso3: 'MMR',
    isoName: 'Myanmar',
    name: 'Myanmar',
  },
  MN: {
    code: 'MN',
    isEnabled: true,
    iso3: 'MNG',
    isoName: 'Mongolia',
    name: 'Mongolia',
  },
  MO: {
    code: 'MO',
    isEnabled: true,
    iso3: 'MAC',
    isoName: 'Macao',
    name: 'Macao',
  },
  MP: {
    code: 'MP',
    isEnabled: true,
    iso3: 'MNP',
    isoName: 'Northern Mariana Islands',
    name: 'Northern Mariana Islands',
  },
  MQ: {
    code: 'MQ',
    isEnabled: true,
    iso3: 'MTQ',
    isoName: 'Martinique',
    name: 'Martinique',
  },
  MR: {
    code: 'MR',
    isEnabled: true,
    iso3: 'MRT',
    isoName: 'Mauritania',
    name: 'Mauritania',
  },
  MS: {
    code: 'MS',
    isEnabled: true,
    iso3: 'MSR',
    isoName: 'Montserrat',
    name: 'Montserrat',
  },
  MT: {
    code: 'MT',
    isEnabled: true,
    iso3: 'MLT',
    isoName: 'Malta',
    name: 'Malta',
  },
  MU: {
    code: 'MU',
    isEnabled: true,
    iso3: 'MUS',
    isoName: 'Mauritius',
    name: 'Mauritius',
  },
  MV: {
    code: 'MV',
    isEnabled: true,
    iso3: 'MDV',
    isoName: 'Maldives',
    name: 'Maldives',
  },
  MW: {
    code: 'MW',
    isEnabled: true,
    iso3: 'MWI',
    isoName: 'Malawi',
    name: 'Malawi',
  },
  MX: {
    code: 'MX',
    isEnabled: true,
    iso3: 'MEX',
    isoName: 'Mexico',
    name: 'Mexico',
  },
  MY: {
    code: 'MY',
    isEnabled: true,
    iso3: 'MYS',
    isoName: 'Malaysia',
    name: 'Malaysia',
  },
  MZ: {
    code: 'MZ',
    isEnabled: true,
    iso3: 'MOZ',
    isoName: 'Mozambique',
    name: 'Mozambique',
  },
  NA: {
    code: 'NA',
    isEnabled: true,
    iso3: 'NAM',
    isoName: 'Namibia',
    name: 'Namibia',
  },
  NC: {
    code: 'NC',
    isEnabled: true,
    iso3: 'NCL',
    isoName: 'New Caledonia',
    name: 'New Caledonia',
  },
  NE: {
    code: 'NE',
    isEnabled: true,
    iso3: 'NER',
    isoName: 'Niger',
    name: 'Niger',
  },
  NF: {
    code: 'NF',
    isEnabled: true,
    iso3: 'NFK',
    isoName: 'Norfolk Island',
    name: 'Norfolk Island',
  },
  NG: {
    code: 'NG',
    isEnabled: true,
    iso3: 'NGA',
    isoName: 'Nigeria',
    name: 'Nigeria',
  },
  NI: {
    code: 'NI',
    isEnabled: true,
    iso3: 'NIC',
    isoName: 'Nicaragua',
    name: 'Nicaragua',
  },
  NL: {
    code: 'NL',
    isEnabled: true,
    iso3: 'NLD',
    isoName: 'Netherlands',
    name: 'The Netherlands',
  },
  NO: {
    code: 'NO',
    isEnabled: true,
    iso3: 'NOR',
    isoName: 'Norway',
    name: 'Norway',
  },
  NP: {
    code: 'NP',
    isEnabled: true,
    iso3: 'NPL',
    isoName: 'Nepal',
    name: 'Nepal',
  },
  NR: {
    code: 'NR',
    isEnabled: true,
    iso3: 'NRU',
    isoName: 'Nauru',
    name: 'Nauru',
  },
  NU: {
    code: 'NU',
    isEnabled: true,
    iso3: 'NIU',
    isoName: 'Niue',
    name: 'Niue',
  },
  NZ: {
    code: 'NZ',
    isEnabled: true,
    iso3: 'NZL',
    isoName: 'New Zealand',
    name: 'New Zealand',
  },
  OM: {
    code: 'OM',
    isEnabled: true,
    iso3: 'OMN',
    isoName: 'Oman',
    name: 'Oman',
  },
  PA: {
    code: 'PA',
    isEnabled: true,
    iso3: 'PAN',
    isoName: 'Panama',
    name: 'Panama',
  },
  PE: {
    code: 'PE',
    isEnabled: true,
    iso3: 'PER',
    isoName: 'Peru',
    name: 'Peru',
  },
  PF: {
    code: 'PF',
    isEnabled: true,
    iso3: 'PYF',
    isoName: 'French Polynesia',
    name: 'French Polynesia',
  },
  PG: {
    code: 'PG',
    isEnabled: true,
    iso3: 'PNG',
    isoName: 'Papua New Guinea',
    name: 'Papua New Guinea',
  },
  PH: {
    code: 'PH',
    isEnabled: true,
    iso3: 'PHL',
    isoName: 'Philippines',
    name: 'Philippines',
  },
  PK: {
    code: 'PK',
    isEnabled: true,
    iso3: 'PAK',
    isoName: 'Pakistan',
    name: 'Pakistan',
  },
  PL: {
    code: 'PL',
    isEnabled: true,
    iso3: 'POL',
    isoName: 'Poland',
    name: 'Poland',
  },
  PM: {
    code: 'PM',
    isEnabled: true,
    iso3: 'SPM',
    isoName: 'Saint Pierre and Miquelon',
    name: 'St. Pierre and Miquelon',
  },
  PN: {
    code: 'PN',
    isEnabled: true,
    iso3: 'PCN',
    isoName: 'Pitcairn',
    name: 'Pitcairn',
  },
  PR: {
    code: 'PR',
    isEnabled: true,
    iso3: 'PRI',
    isoName: 'Puerto Rico',
    name: 'Puerto Rico',
  },
  PS: {
    code: 'PS',
    isEnabled: true,
    iso3: 'PSE',
    isoName: 'State of Palestine',
    name: 'Palestinian Territory, Occupied',
  },
  PT: {
    code: 'PT',
    isEnabled: true,
    iso3: 'PRT',
    isoName: 'Portugal',
    name: 'Portugal',
  },
  PW: {
    code: 'PW',
    isEnabled: true,
    iso3: 'PLW',
    isoName: 'Palau',
    name: 'Palau',
  },
  PY: {
    code: 'PY',
    isEnabled: true,
    iso3: 'PRY',
    isoName: 'Paraguay',
    name: 'Paraguay',
  },
  QA: {
    code: 'QA',
    isEnabled: true,
    iso3: 'QAT',
    isoName: 'Qatar',
    name: 'Qatar',
  },
  RE: {
    code: 'RE',
    isEnabled: true,
    iso3: 'REU',
    isoName: 'Reunion',
    name: 'Reunion',
  },
  RO: {
    code: 'RO',
    isEnabled: true,
    iso3: 'ROU',
    isoName: 'Romania',
    name: 'Romania',
  },
  RS: {
    code: 'RS',
    isEnabled: true,
    iso3: 'SRB',
    isoName: 'Serbia',
    name: 'Serbia',
  },
  RU: {
    code: 'RU',
    isEnabled: true,
    iso3: 'RUS',
    isoName: 'Russian Federation',
    name: 'Russia',
  },
  RW: {
    code: 'RW',
    isEnabled: true,
    iso3: 'RWA',
    isoName: 'Rwanda',
    name: 'Rwanda',
  },
  SA: {
    code: 'SA',
    isEnabled: true,
    iso3: 'SAU',
    isoName: 'Saudi Arabia',
    name: 'Saudi Arabia',
  },
  SB: {
    code: 'SB',
    isEnabled: true,
    iso3: 'SLB',
    isoName: 'Solomon Islands',
    name: 'Solomon Islands',
  },
  SC: {
    code: 'SC',
    isEnabled: true,
    iso3: 'SYC',
    isoName: 'Seychelles',
    name: 'Seychelles',
  },
  SD: {
    code: 'SD',
    isEnabled: true,
    iso3: 'SDN',
    isoName: 'Sudan',
    name: 'Sudan',
  },
  SE: {
    code: 'SE',
    isEnabled: true,
    iso3: 'SWE',
    isoName: 'Sweden',
    name: 'Sweden',
  },
  SG: {
    code: 'SG',
    isEnabled: true,
    iso3: 'SGP',
    isoName: 'Singapore',
    name: 'Singapore',
  },
  SH: {
    code: 'SH',
    isEnabled: true,
    iso3: 'SHN',
    isoName: 'Saint Helena',
    name: 'St. Helena',
  },
  SI: {
    code: 'SI',
    isEnabled: true,
    iso3: 'SVN',
    isoName: 'Slovenia',
    name: 'Slovenia',
  },
  SJ: {
    code: 'SJ',
    isEnabled: true,
    iso3: 'SJM',
    isoName: 'Svalbard and Jan Mayen',
    name: 'Svalbard and Jan Mayen',
  },
  SK: {
    code: 'SK',
    isEnabled: true,
    iso3: 'SVK',
    isoName: 'Slovakia',
    name: 'Slovak Republic',
  },
  SL: {
    code: 'SL',
    isEnabled: true,
    iso3: 'SLE',
    isoName: 'Sierra Leone',
    name: 'Sierra Leone',
  },
  SM: {
    code: 'SM',
    isEnabled: true,
    iso3: 'SMR',
    isoName: 'San Marino',
    name: 'San Marino',
  },
  SN: {
    code: 'SN',
    isEnabled: true,
    iso3: 'SEN',
    isoName: 'Senegal',
    name: 'Senegal',
  },
  SO: {
    code: 'SO',
    isEnabled: true,
    iso3: 'SOM',
    isoName: 'Somalia',
    name: 'Somalia',
  },
  SR: {
    code: 'SR',
    isEnabled: true,
    iso3: 'SUR',
    isoName: 'Suriname',
    name: 'Suriname',
  },
  SS: {
    code: 'SS',
    isEnabled: true,
    iso3: 'SSD',
    isoName: 'South Sudan',
    name: 'South Sudan',
  },
  ST: {
    code: 'ST',
    isEnabled: true,
    iso3: 'STP',
    isoName: 'Sao Tome and Principe',
    name: 'Sao Tome and Principe',
  },
  SV: {
    code: 'SV',
    isEnabled: true,
    iso3: 'SLV',
    isoName: 'El Salvador',
    name: 'El Salvador',
  },
  SX: {
    code: 'SX',
    isEnabled: true,
    iso3: 'SXM',
    isoName: 'Sint Maarten (Dutch part)',
    name: 'St. Maarten',
  },
  SY: {
    code: 'SY',
    isEnabled: true,
    iso3: 'SYR',
    isoName: 'Syrian Arab Republic',
    name: 'Syria',
  },
  SZ: {
    code: 'SZ',
    isEnabled: true,
    iso3: 'SWZ',
    isoName: 'Eswatini',
    name: 'Swaziland',
  },
  TC: {
    code: 'TC',
    isEnabled: true,
    iso3: 'TCA',
    isoName: 'Turks and Caicos Islands',
    name: 'Turks and Caicos Islands',
  },
  TD: {
    code: 'TD',
    isEnabled: true,
    iso3: 'TCD',
    isoName: 'Chad',
    name: 'Chad',
  },
  TF: {
    code: 'TF',
    isEnabled: true,
    iso3: 'ATF',
    isoName: 'French Southern Territories',
    name: 'French Southern Territories',
  },
  TG: {
    code: 'TG',
    isEnabled: true,
    iso3: 'TGO',
    isoName: 'Togo',
    name: 'Togo',
  },
  TH: {
    code: 'TH',
    isEnabled: true,
    iso3: 'THA',
    isoName: 'Thailand',
    name: 'Thailand',
  },
  TJ: {
    code: 'TJ',
    isEnabled: true,
    iso3: 'TJK',
    isoName: 'Tajikistan',
    name: 'Tajikistan',
  },
  TK: {
    code: 'TK',
    isEnabled: true,
    iso3: 'TKL',
    isoName: 'Tokelau',
    name: 'Tokelau',
  },
  TL: {
    code: 'TL',
    isEnabled: true,
    iso3: 'TLS',
    isoName: 'Timor-Leste',
    name: 'Timor-Leste',
  },
  TM: {
    code: 'TM',
    isEnabled: true,
    iso3: 'TKM',
    isoName: 'Turkmenistan',
    name: 'Turkmenistan',
  },
  TN: {
    code: 'TN',
    isEnabled: true,
    iso3: 'TUN',
    isoName: 'Tunisia',
    name: 'Tunisia',
  },
  TO: {
    code: 'TO',
    isEnabled: true,
    iso3: 'TON',
    isoName: 'Tonga',
    name: 'Tonga',
  },
  TR: {
    code: 'TR',
    isEnabled: true,
    iso3: 'TUR',
    isoName: 'Türkiye',
    name: 'Turkey',
  },
  TT: {
    code: 'TT',
    isEnabled: true,
    iso3: 'TTO',
    isoName: 'Trinidad and Tobago',
    name: 'Trinidad and Tobago',
  },
  TV: {
    code: 'TV',
    isEnabled: true,
    iso3: 'TUV',
    isoName: 'Tuvalu',
    name: 'Tuvalu',
  },
  TW: {
    code: 'TW',
    isEnabled: true,
    iso3: 'TWN',
    isoName: 'Taiwan, Province of China',
    name: 'Taiwan, Province of China',
  },
  TZ: {
    code: 'TZ',
    isEnabled: true,
    iso3: 'TZA',
    isoName: 'United Republic of Tanzania',
    name: 'Tanzania',
  },
  UA: {
    code: 'UA',
    isEnabled: true,
    iso3: 'UKR',
    isoName: 'Ukraine',
    name: 'Ukraine',
  },
  UG: {
    code: 'UG',
    isEnabled: true,
    iso3: 'UGA',
    isoName: 'Uganda',
    name: 'Uganda',
  },
  UM: {
    code: 'UM',
    isEnabled: true,
    iso3: 'UMI',
    isoName: 'United States Minor Outlying Islands',
    name: 'United States Minor Outlying Island',
  },
  US: {
    code: 'US',
    isEnabled: true,
    iso3: 'USA',
    isoName: 'United States of America',
    name: 'United States',
  },
  UU: {
    code: 'UU',
    isEnabled: false,
    iso3: 'UUU',
    isoName: '',
    name: 'At Sea',
  },
  UY: {
    code: 'UY',
    isEnabled: true,
    iso3: 'URY',
    isoName: 'Uruguay',
    name: 'Uruguay',
  },
  UZ: {
    code: 'UZ',
    isEnabled: true,
    iso3: 'UZB',
    isoName: 'Uzbekistan',
    name: 'Uzbekistan',
  },
  VA: {
    code: 'VA',
    isEnabled: true,
    iso3: 'VAT',
    isoName: 'Holy See (Vatican City State)',
    name: 'Vatican City State (Holy See)',
  },
  VC: {
    code: 'VC',
    isEnabled: true,
    iso3: 'VCT',
    isoName: 'Saint Vincent and the Grenadines',
    name: 'Saint Vincent and the Grenadines',
  },
  VE: {
    code: 'VE',
    isEnabled: true,
    iso3: 'VEN',
    isoName: 'Venezuela',
    name: 'Venezuela',
  },
  VG: {
    code: 'VG',
    isEnabled: true,
    iso3: 'VGB',
    isoName: 'Virgin Islands, British',
    name: 'Virgin Islands (British)',
  },
  VI: {
    code: 'VI',
    isEnabled: true,
    iso3: 'VIR',
    isoName: 'Virgin Islands, U.S.',
    name: 'Virgin Islands (US)',
  },
  VN: {
    code: 'VN',
    isEnabled: true,
    iso3: 'VNM',
    isoName: 'Vietnam',
    name: 'Vietnam',
  },
  VU: {
    code: 'VU',
    isEnabled: true,
    iso3: 'VUT',
    isoName: 'Vanuatu',
    name: 'Vanuatu',
  },
  WF: {
    code: 'WF',
    isEnabled: true,
    iso3: 'WLF',
    isoName: 'Wallis and Futuna',
    name: 'Wallis and Futuna Islands',
  },
  WS: {
    code: 'WS',
    isEnabled: true,
    iso3: 'WSM',
    isoName: 'Samoa',
    name: 'Samoa',
  },
  XK: {
    code: 'XK',
    isEnabled: true,
    iso3: 'XKX',
    isoName: 'Kosovo',
    name: 'Kosovo',
  },
  YE: {
    code: 'YE',
    isEnabled: true,
    iso3: 'YEM',
    isoName: 'Yemen',
    name: 'Yemen',
  },
  YT: {
    code: 'YT',
    isEnabled: true,
    iso3: 'MYT',
    isoName: 'Mayotte',
    name: 'Mayotte',
  },
  YU: {
    code: 'YU',
    isEnabled: false,
    iso3: 'YUG',
    isoName: '',
    name: 'Yugoslavia',
  },
  ZA: {
    code: 'ZA',
    isEnabled: true,
    iso3: 'ZAF',
    isoName: 'South Africa',
    name: 'South Africa',
  },
  ZM: {
    code: 'ZM',
    isEnabled: true,
    iso3: 'ZMB',
    isoName: 'Zambia',
    name: 'Zambia',
  },
  ZW: {
    code: 'ZW',
    isEnabled: true,
    iso3: 'ZWE',
    isoName: 'Zimbabwe',
    name: 'Zimbabwe',
  },
  ZZ: {
    code: 'ZZ',
    isEnabled: false,
    iso3: 'ZZZ',
    isoName: '',
    name: 'At Sea',
  },
};

export { EVO_COUNTRY };
