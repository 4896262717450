import useAuth from 'hooks/useAuth';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { removeLoader } from 'utils/loader';
import { getRouteFromPathname } from 'utils/routing';

const AuthInitializer = ({ children }) => {
  const { initialize } = useAuth();
  const [isAccountFound, setIsAccountFound] = useState(false);
  const route = getRouteFromPathname();

  useEffect(() => {
    (async () => {
      await initialize(({ account }) => {
        setIsAccountFound(!!account);
        if (isAccountFound || route.isPublic) {
          removeLoader();
        }
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // NOTE: null is the fallback here, but should never get hit
  // as those scenarios are handled elsewhere.
  // See https://vikingtravel.atlassian.net/browse/TAP-3226 for more details.
  return isAccountFound || route.isPublic ? children : null;
};

AuthInitializer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthInitializer;
