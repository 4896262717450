import { BASE_PATH } from 'constants/API';
import { STORAGE_KEYS } from 'constants/CONFIG';
import ROUTES from 'constants/ROUTES';
import useLocalStorage, { STORAGE_TYPES } from 'hooks/useLocalStorage';
import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const HistoryContext = createContext({});

const HistoryProvider = ({ children }) => {
  const location = useLocation();
  const homeUrls = [`${BASE_PATH}${ROUTES.home.url}`, `${BASE_PATH}/`, BASE_PATH];

  const [history, setHistory] = useLocalStorage({
    key: STORAGE_KEYS.history,
    initialValue: {
      hasHistory: false,
      isHome: homeUrls.includes(location.pathname),
      isLastHome: homeUrls.includes(location.pathname),
      lastPathName: location.pathname,
      list: [location.pathname],
    },
    storageType: STORAGE_TYPES.sessionStorage,
  });

  useEffect(() => {
    const isRefreshed = location.pathname === history.list[history.list.length - 1];
    if (!isRefreshed) {
      const isHome = homeUrls.includes(location.pathname);
      let list = [];
      if (isHome) {
        list = [`${BASE_PATH}${ROUTES.home.url}`];
      } else {
        const lastHistoryPath = history.list?.[history.list.length - 2] || `${BASE_PATH}${ROUTES.home.url}`;
        list =
          lastHistoryPath === location.pathname
            ? history.list.slice(0, history.list.length - 1)
            : [...history.list, location.pathname];
      }
      const lastPathName = list?.[list.length - 2] || `${BASE_PATH}${ROUTES.home.url}`;
      setHistory({
        hasHistory: list?.length > 1,
        isHome,
        isLastHome: homeUrls.includes(lastPathName),
        lastPathName: list?.[list.length - 2] || `${BASE_PATH}${ROUTES.home.url}`, // Used for HOME/BACK button functionality
        list,
        previousPage: history.list[history.list.length - 1],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getHistory = () => history.list;
  const resetHistory = () => {
    setHistory({
      hasHistory: false,
      isHome: true,
      isLastHome: true,
      lastPathName: `${BASE_PATH}${ROUTES.home.url}`,
      list: [`${BASE_PATH}${ROUTES.home.url}`],
      previousPage: `${BASE_PATH}${ROUTES.home.url}`,
    });
  };

  const value = useMemo(
    () => ({
      getHistory,
      hasHistory: history.hasHistory,
      isHome: history.isHome,
      isLastHome: history.isLastHome,
      lastPathName: history.lastPathName,
      previousPage: history.previousPage,
      resetHistory,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );

  return <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>;
};

HistoryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HistoryProvider;

export { HistoryContext };
