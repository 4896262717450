const env = window?.location?.hostname?.includes('localhost') ? process.env : window.env;

const IS_LOCAL = ['true', true].includes(env.REACT_APP_IS_LOCAL) || '';

const IS_APP_INSIGHTS_ENABLED = !IS_LOCAL;
const IS_LIVE_PERSON_ENABLED = !IS_LOCAL;

const ADOBE_DTM_KEY = env.REACT_APP_ADOBE_DTM_KEY || '';
const B2C_API_URI = env.REACT_APP_B2C_API_URI || '';
const B2C_CLIENT_ID = env.REACT_APP_B2C_CLIENT_ID || '';
const B2C_DOMAIN = env.REACT_APP_B2C_DOMAIN || '';
const B2C_TENANT_ID = env.REACT_APP_B2C_TENANT_ID || '';
const ENVIRONMENT_CODE = env.REACT_APP_ENVIRONMENT_CODE || '';
const MVJ_API_BASE_URL = env.REACT_APP_MVJ_API_BASE_URL || '';
const OLB_API_BASE_URL = env.REACT_APP_OLB_API_BASE_URL || '';
const TAP_API_BASE_URL = env.REACT_APP_TAP_API_BASE_URL || '';

const MW_BASE_URLS = Object.freeze({
  expeditions: env.REACT_APP_MW_EXPEDITIONS_BASE_URL || '',
  oceans: env.REACT_APP_MW_OCEANS_BASE_URL || '',
  rivers: env.REACT_APP_MW_RIVERS_BASE_URL || '',
});

export {
  ADOBE_DTM_KEY,
  B2C_API_URI,
  B2C_CLIENT_ID,
  B2C_DOMAIN,
  B2C_TENANT_ID,
  ENVIRONMENT_CODE,
  IS_APP_INSIGHTS_ENABLED,
  IS_LIVE_PERSON_ENABLED,
  IS_LOCAL,
  MVJ_API_BASE_URL,
  MW_BASE_URLS,
  OLB_API_BASE_URL,
  TAP_API_BASE_URL,
};
