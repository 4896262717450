import { useCallback, useState } from 'react';
import { logError } from 'utils/logging';

const STORAGE_TYPES = Object.freeze({
  localStorage: 'localStorage',
  sessionStorage: 'sessionStorage',
});

const useLocalStorage = ({ key, initialValue, isInitialValueMerged, storageType = STORAGE_TYPES.localStorage }) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window[storageType].getItem(key);
      let returnItem = item ? JSON.parse(item) : initialValue;
      if (isInitialValueMerged && typeof initialValue === 'object') {
        returnItem = { ...returnItem, ...initialValue };
      }
      if (typeof window !== 'undefined') {
        window[storageType].setItem(key, JSON.stringify(returnItem));
      }
      return returnItem;
    } catch (error) {
      logError('useLocalStorage - useState', error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        if (typeof window !== 'undefined') {
          window[storageType].setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        logError('useLocalStorage - setValue', error);
      }
    },
    [key, storageType, storedValue]
  );

  return [storedValue, setValue];
};

export default useLocalStorage;

export { STORAGE_TYPES };
