/* eslint-disable react/jsx-filename-extension */
import { MsalProvider } from '@azure/msal-react';
import AnalyticsSite from 'common/analytics/AnalyticsSite';
import AuthInitializer from 'common/auth/AuthInitializer';
import RootError from 'common/error/RootError';
import CommonWrapper from 'common/layout/CommonWrapper';
import PageLoading from 'common/pageLoading/PageLoading';
import { IS_APP_INSIGHTS_ENABLED, IS_LIVE_PERSON_ENABLED } from 'constants/ENV';
import { init as mockInit } from 'mocks/browser';
import { lazy, StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { initialize } from 'utils/auth';
import { logError } from 'utils/logging';
import { createStore } from './app/store';

const App = lazy(() => import('app/App'));
const CookiesCheck = lazy(() => import('common/cookiesCheck/CookiesCheck'));

(async () => {
  await mockInit();
  const msalInstance = await initialize();
  const { persistor, store } = createStore();

  if (IS_APP_INSIGHTS_ENABLED) {
    import('utils/appInsights');
  }

  if (IS_LIVE_PERSON_ENABLED) {
    import('@viking-eng/live-person').then((livePerson) => {
      if (!livePerson) {
        // eslint-disable-next-line no-console
        console.warn('LivePerson is not available.');
      }
    });
  }

  createRoot(document.getElementById('root')).render(
    <ReactErrorBoundary FallbackComponent={RootError} onError={(error, info) => logError('App', { error, info })}>
      <StrictMode>
        <Suspense fallback={null}>
          <Provider store={store}>
            <MsalProvider instance={msalInstance}>
              <AuthInitializer>
                <AnalyticsSite>
                  <CookiesCheck>
                    <PersistGate loading={<PageLoading />} persistor={persistor}>
                      <CommonWrapper>
                        <App store={store} />
                      </CommonWrapper>
                    </PersistGate>
                  </CookiesCheck>
                </AnalyticsSite>
              </AuthInitializer>
            </MsalProvider>
          </Provider>
        </Suspense>
      </StrictMode>
    </ReactErrorBoundary>
  );
})();
