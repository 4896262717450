const MAX_LENGTHS = Object.freeze({
  ADDRESS: 35,
  AGENCY: 50,
  CITY: 35,
  EMAIL: 64,
  FIRST_NAME: 15,
  FULL_NAME: 50,
  LAST_NAME: 22,
  MIDDLE_NAME: 35,
  POST_ZIP: 5,
  STATE: 35,
  WEBSITE_SUFFIX: 20,
});

const MIN_LENGTHS = Object.freeze({
  ADDRESS: 2,
  CITY: 2,
  NAME: 2,
  PHONE: 7,
});

const VALIDATION_REGEXES = Object.freeze({
  ADDRESS: /[^a-zA-Z0-9.,'#\-/ ]/,
  ADDRESS_KEY_PRESS: /^[-a-zA-Z0-9.,'#\-/ ]/,
  ALPHA_NUMERIC_SPACES: /[a-zA-Z0-9 ]/,
  ALPHA_NUMERIC: /[a-zA-Z0-9]/,
  ALPHA: /[a-zA-Z ]/,
  BOOKING_NUMBER: /^\d{7,10}$/,
  CITY: /[^\w .,'-]+$/,
  EMAIL: /[^\s]*@[a-zA-Z0-9-_.@]*/,
  EMAIL_KEY_PRESS: /^[-a-zA-Z0-9-_.@]/,
  FAX: /[^0-9\-() ]/,
  FULL_NAME: /[^\w .'-]+$/,
  INVALID: /[^a-zA-Z0-9.,?\-/!@#$%^&*()_+=|:;'" ]/,
  NAME: /^[-a-zA-Z-'. ]+(\s+[-a-zA-Z-'. ]+)*$/,
  NUMERIC: /^\d+$/,
  MOCK_PAST_PASSENGER: /^\d{12}$/,
  PHONE: /[^0-9\-() ]/,
  POST_ZIP: /[^a-zA-Z0-9.,' ]{4,9}/,
  POSTZIP_KEY_PRESS: /^[a-zA-Z0-9 ]/,
  WEBSITE_SUFFIX: /[^a-zA-Z0-9-]/,
  WEBSITE_SUFFIX_PRESS: /^[-a-zA-Z0-9\- ]/,
  AGENCY_NAME: /[^a-zA-Z0-9.,+()@,/&#'=\- ]/,
  AGENCY_NAME_PRESS: /^[-a-zA-Z0-9.,+()@,/&#'=\- ]/,
});

export { MAX_LENGTHS, MIN_LENGTHS, VALIDATION_REGEXES };
