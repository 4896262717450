/* eslint-disable no-console */
const logError = (message, ...rest) =>
  console.error(
    `%c ${message} ${rest.length !== 0 ? JSON.stringify(rest, null, 2) : ''}`,
    'color: LightCoral; background: black;'
  );

const logInfo = (message, ...rest) =>
  console.info(
    `%c ${message} ${rest.length !== 0 ? JSON.stringify(rest, null, 2) : ''}`,
    'color: lime; background: black;'
  );

const logWarn = (message, ...rest) =>
  console.info(
    `%c ${message} ${rest.length !== 0 ? JSON.stringify(rest, null, 2) : ''}`,
    'color: orange; background: black;'
  );

export { logError, logInfo, logWarn };
