import { CURRENCY_BY_COUNTRY, OFFICES } from 'constants/COUNTRIES';
import ROUTES from 'constants/ROUTES';

const AUTH_AGENCY_MAX_LENGTH = 10;

const AUTH_ERROR_CODES = Object.freeze({
  ACCESS_DENIED: 'access_denied',
  AUTHORITY_MISMATCH: 'authority_mismatch',
  INTERACTION_IN_PROGRESS: 'interaction_in_progress',
  INVALID_GRANT: 'invalid_grant',
});

const AUTH_ERRORS = Object.freeze({
  IS_USER_NOT_SIGNED_IN: 'User is not signed in.',
});

const AUTH_LOGIN_MAX_AUTO_TRIES = 3;

const B2C_AUTH_ERROR_CODES = Object.freeze({
  INCORRECT_POLICY: 'AADB2C90088',
  CANCEL_ACTION: 'AADB2C90091',
  NO_SESSION: 'AADB2C90077',
});

const AUTH_STATUS = Object.freeze({
  gettingAuth: 'gettingAuth',
  gettingToken: 'gettingToken',
});

const AUTH_TOKEN_INTERVAL_MILLISECONDS = 15 * 60 * 1000; // 15 minutes

const AUTH_USER_TYPES = Object.freeze({
  TA: 'TA',
  CONSUMER: 'DIRECT',
  CUSTOMER_SERVICE: 'CSA',
});

const CURRENCY_LOCALE_MAP = Object.freeze({
  AUD: 'en-AU',
  GBP: 'en-GB',
  USD: 'en-US',
  CAD: 'en-US',
});

const INITIAL_STATE = Object.freeze({
  agency: { country: OFFICES.US, currency: CURRENCY_BY_COUNTRY.US.code, office: OFFICES.US },
  agent: { country: OFFICES.US, currency: CURRENCY_BY_COUNTRY.US.code, office: OFFICES.US },
  msal: {},
});

const PUBLIC_ROUTES = Object.values(ROUTES)
  .filter((route) => route.isPublic)
  .map(({ url }) => url);

export {
  AUTH_AGENCY_MAX_LENGTH,
  AUTH_ERROR_CODES,
  AUTH_ERRORS,
  AUTH_LOGIN_MAX_AUTO_TRIES,
  AUTH_STATUS,
  AUTH_TOKEN_INTERVAL_MILLISECONDS,
  AUTH_USER_TYPES,
  B2C_AUTH_ERROR_CODES,
  CURRENCY_LOCALE_MAP,
  INITIAL_STATE,
  PUBLIC_ROUTES,
};
