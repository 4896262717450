import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsightsHelper } from '@viking-eng/telemetry';
import { IS_APP_INSIGHTS_ENABLED, IS_LOCAL } from 'constants/ENV';
import {
  IS_DEBUG_LOG_LEVEL_ENABLED,
  IS_ERROR_LOG_LEVEL_ENABLED,
  IS_INFO_LOG_LEVEL_ENABLED,
  IS_WARN_LOG_LEVEL_ENABLED,
  LOGGING_LEVELS,
  LOG_LEVEL,
} from 'constants/LOGGING';

const consoleOutput = (logLevel, ...logParams) => {
  if (LOG_LEVEL === LOGGING_LEVELS.SILENT) {
    return;
  }

  /* eslint-disable no-console */
  const funcList = {
    DEBUG: console.log,
    INFO: console.info,
    WARN: console.warn,
    ERROR: console.error,
  };
  const logFunction = funcList[logLevel] || undefined;
  /* eslint-enable no-console */
  if (logFunction) {
    logFunction(...logParams);
  }
};

const localLogger = (logData, condition = true) => {
  if (IS_LOCAL && condition) {
    // eslint-disable-next-line no-console
    console.log(logData);
  }
};

const logAppInsightsEvent = ({ name, severity, logData, type }) => {
  if (IS_APP_INSIGHTS_ENABLED) {
    appInsightsHelper.logger({ name, severity, logData, type });
  }
};

const logDebug = (name, ...logData) => {
  if (IS_DEBUG_LOG_LEVEL_ENABLED) {
    const { severity = SeverityLevel.Verbose } = logData;
    consoleOutput(LOGGING_LEVELS.DEBUG, ...logData);
    logAppInsightsEvent({ name, severity, logData, type: 'EVENT' });
  }
};

const logError = (name, ...logData) => {
  if (IS_ERROR_LOG_LEVEL_ENABLED) {
    const { severity = SeverityLevel.Error } = logData;
    consoleOutput(LOGGING_LEVELS.ERROR, ...logData);
    logAppInsightsEvent({ name, severity, logData, type: 'EVENT' });
  }
};

const logInfo = (name, ...logData) => {
  if (IS_INFO_LOG_LEVEL_ENABLED) {
    const { severity = SeverityLevel.Information } = logData;
    consoleOutput(LOGGING_LEVELS.INFO, ...logData);
    logAppInsightsEvent({ name, severity, logData, type: 'EVENT' });
  }
};

const logWarning = (name, ...logData) => {
  if (IS_WARN_LOG_LEVEL_ENABLED) {
    const { severity = SeverityLevel.Warning } = logData;
    consoleOutput(LOGGING_LEVELS.WARN, ...logData);
    logAppInsightsEvent({ name, severity, logData, type: 'EVENT' });
  }
};

const logAppInsights = ({ logData, name, severity = SeverityLevel.Information, type = 'EVENT' }) => {
  switch (severity) {
    case SeverityLevel.Critical:
      logError(name, { logData, severity, type });
      break;
    case SeverityLevel.Error:
      logError(name, { logData, severity, type });
      break;
    case SeverityLevel.Information:
      logInfo(name, { logData, severity, type });
      break;
    case SeverityLevel.Verbose:
      logDebug(name, { logData, severity, type });
      break;
    case SeverityLevel.Warning:
      logWarning(name, { logData, severity, type });
      break;
    default:
      logError(name, { logData, severity, type });
      break;
  }
};

export { consoleOutput, localLogger, logAppInsights, logDebug, logError, logInfo, logWarning };
